<template>
  <div class="wrapper position-relative align-items-center d-flex flex-column pb-lg-3 position-relative" :style="$root.gridBreakpoint >= $gridSizes.sm ? background : ''"
            data-elb-context="shopping:discover" data-elb="product" :data-elb-product="elbData">
    <div :class="{'table' : $root.gridBreakpoint >= $gridSizes.sm}"></div>
    <div class="container px-0 pt-0 pt-md-5 pt-lg-2 d-flex flex-column flex-sm-column-reverse flex-lg-row flex-grow-1 position-relative">
      <div v-if="coop" class="coop bg-white py-2 px-3 mx-md-3 d-flex">
        <img :src="coop" :alt="translate('opc_coop_img_alt')"/>
      </div>
      <div class="col-12 col-lg-7 mx-auto px-0"  :style="$root.gridBreakpoint < $gridSizes.sm ? background : ''">
        <div :class="{'table' : $root.gridBreakpoint < $gridSizes.sm}" class="h-100 w-100 position-relative d-flex justify-content-center">
          <ProductImage class="px-5 px-lg-0 w-100" :image-src="image.src" :image-retina-src="image.retina_src"
                        :alt="product.general.name" :is-bundle="isBundle"/>
          <TheOpcAwards v-if="badges.length" class="award-list pt-5 pt-sm-3 pt-lg-3 pr-3 pr-md-5 pr-lg-3"
                        :awards="badges" :threshold="awardThreshold" placement="left"/>
        </div>
      </div>
      <div class="stage-info col-12 col-lg-5 mx-auto px-0 align-self-center">
        <TheOpcStageInfo class="px-3 pb-0 pb-sm-2"/>
      </div>
    </div>
    <img v-if="$root.gridBreakpoint >= $gridSizes.sm" width="65" height="35"
         class="arrow mt-3 mt-sm-0 mb-3 mb-lg-0 cursor-pointer"
         src="@/assets/images/svg/arrow_down_white.svg" alt="arrow-down" @click="scrollTo">
  </div>
</template>

<script>
import TheOpcStageInfo from '@/components/opc/molecules/TheOpcStageInfo'
import TheOpcAwards from '@/components/opc/molecules/TheOpcAwards'
import { useOpcStore } from '@/stores/opc'
import { mapState } from 'pinia'
import ProductImage from '@/components/basic/atoms/ProductImage.vue'
import ElbwalkerHelper from '@/services/helpers/ElbwalkerHelper'

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [{ elb }, { WALKER_COMMANDS }] = await Promise.all([
      await import('@elbwalker/walker.js'),
      await import('@/services/helpers/ElbwalkerHelper')
    ])
    cache = { elb, WALKER_COMMANDS }
    return cache
  }
})()

export default {
  name: 'TheOpcStage',
  components: { ProductImage, TheOpcAwards, TheOpcStageInfo  },
  computed: {
    ...mapState(useOpcStore, ['calculated', 'params', 'updating', 'product']),
    badges () {
      return this.params?.badges ?? []
    },
    awardThreshold () {
      return this.$root.gridBreakpoint < this.$gridSizes.md ? 2 : 3
    },
    coop () {
      return this.params.coop
    },
    isBundle () {
      return this.calculated.isBundle
    },
    image () {
      return this.$root.gridBreakpoint < this.$gridSizes.md ?
        this.calculated.images.smallImage : this.calculated.images.image
    },
    background() {
      const { groups: { bg } } = this.$router.currentRoute.path.match(/-bg-(?<bg>\d+)/) ?? { groups: { bg: 1 } }
      const number = bg > 1 && bg < 17 ? bg : 1

      let size
      switch (true) {
        case this.$root.gridBreakpoint < this.$gridSizes.sm:
          size = 'xs'
          break
        case this.$root.gridBreakpoint < this.$gridSizes.md:
          size = 'sm'
          break
        case this.$root.gridBreakpoint < this.$gridSizes.lg:
          size = 'md'
          break
        case this.$root.gridBreakpoint < this.$gridSizes.xl:
          size = 'lg'
          break
        default:
          size = 'xl'
      }

      const image = require(`@/assets/images/opc/backgrounds/bg_${number}_${size}.webp`)
      return `background-image: url(${image});`
    },
    elbData () {
      return ElbwalkerHelper.getProductData(this.product)
    }
  },
  methods: {
    async scrollTo () {
      const { default: DomHelper } = await import('@/services/helpers/DomHelper')
      DomHelper.scrollTo('#opc-body')
    }
  },
  watch: {
    async product() {
      await this.$nextTick()
      window.elb('product visible', this.$el)
    }
  }
}
</script>
<style lang="scss" scoped>
.arrow {
  z-index: 1;
  transition: transform $transition-timing-ease $transition-duration-m;
}

.arrow:hover {
  transform: scale(1.1);
}

.wrapper {
  background-size: cover;
  @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    min-height: 650px;
  }
}

.table {
  background-image: url("@/assets/images/opc/bg_table@0,5x.webp");
  background-repeat: repeat-x;
  background-size: auto 65px;
  background-position: 0 100%;
  position: absolute;
  margin: 0;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;

  :hover img {
    transform: scale(1.2);
  }

  img {
    transition: all .5s;
    width: 60px;
    height: 60px;
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
    background-size: auto 125px;
    background-image: url("@/assets/images/opc/bg_table@0,75x.webp");
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    background-size: auto 135px;
    background-image: url("@/assets/images/opc/bg_table.webp");
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
    background-size: auto 150px;
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
  .wrapper {
    background-position: 50% 50%;
  }
}
.coop {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 50px;
  z-index: 3;
  width: 100%;

  img {
    max-width: 130px;
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'md')) {
    bottom: auto;
    top: 0;
    position: absolute;
    border-radius: 0 0 5px 5px;
    max-height: initial;
    width: auto;

    img {
      width: 100%;
    }
  }
}

::v-deep .product-image {
  height: 200px;
  align-self: flex-end;
  margin-bottom: 25px;

  @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
    height:480px;
    margin-bottom: -10px;
  }
  @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    height:550px;
    margin-bottom: -10px;
  }
}

::v-deep .loading-image {
  padding-bottom: 50px;
  padding-top: 75px;
}
</style>
