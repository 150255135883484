<template>
  <div class="product-legals">
    <div class="my-3" :loading="loading">{{ calculated.volume }}</div>
    <div class="c-gray font-size-s"
         v-html="translate('fe_app_tax_info', product.price.tax, 'link-gray')"
         :loading="loading" />
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { usePdpStore } from '@/stores/pdp'

export default {
  name: 'Legals',
  computed: {
    ...mapState(usePdpStore, { product: 'getApiProduct', calculated: 'getCalculated', loading: 'isUpdating' })
  }
}
</script>
