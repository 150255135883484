<template>
  <div>
    <div class="position-relative" v-for="({ data, template, id }) in awardSlice" :key="id" :id="id" @mouseover="visible = id" @mouseleave="visible = null" >
      <div class="opc-tooltip c-white text-center align-items-center font-italic font-libre font-size-s px-4 justify-center"
           :class="visible === id ? 'd-flex' : 'd-none'" v-html="data.tooltip"></div>
      <Award class="mb-2" :data="data" :template="template" width="66px" height="66px"/>
    </div>
  </div>
</template>

<script>
const Award = () => import('@/components/basic/atoms/Award')

export default {
  name: 'TheOpcAwards',
  components: {  Award },
  props: {
    awards: Array,
    threshold: {
      type: Number,
      default: 2
    },
    displayTooltip: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      visible: null,
      calculatedAwards: []
    }
  },
  computed: {
    awardSlice () {
      return this.calculatedAwards.slice(0, this.threshold)
    }
  },
  async created () {
    const { default: UtilityHelper } = await import('@/services/helpers/UtilityHelper')
    const slice = this.awards.slice(0, this.threshold)

    this.calculatedAwards = slice.reduce((awards, award) =>
        [...awards, { id: `award_${UtilityHelper.getRandomHash()}`, ...award }]
    ,[])
  }
}
</script>

<style lang="scss" scoped>
.opc-tooltip {
  position: absolute;
  top: 10px;
  right: 55px;
  width: 200px;
  height: 45px;
  background-image: url('@/assets/images/opc/bg_tooltip.svg');
  background-size: cover;
  background-position: center;
}
</style>
